const controllerName = "/Settings";

export default {
  getSettingsConfig: type => {
    return {
      method: "post",
      url: `${controllerName}/GetSettings?type=${type}`,
    };
  },
  updateSettingConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateSetting`,
      data: payload,
    };
  },
  uploadLogoConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UploadLogo`,
      data: payload,
    };
  },
  addLanguageConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddLanguage`,
      data: payload,
    };
  },
  updateLanguageConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateLanguage`,
      data: payload,
    };
  },
  deleteLanguageConfig: languageId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteLanguage?languageId=${languageId}`,
    };
  },
  getResourceTranslationsConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/GetResourceTranslations`,
      data: payload,
    };
  },
  importTranslationConfig: (payload, langCode) => {
    return {
      method: "post",
      url: `${controllerName}/ImportTranslation?langCode=${langCode}`,
      data: payload,
    };
  },
  addCurrencyConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddCurrency`,
      data: payload,
    };
  },
  updateCurrencyConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateCurrency`,
      data: payload,
    };
  },
  deleteCurrencyConfig: currencyId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteCurrency?currencyId=${currencyId}`,
    };
  },
  addPCodeConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddPCode`,
      data: payload,
    };
  },
  updatePCodeConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdatePCode`,
      data: payload,
    };
  },
  deletePCodeConfig: pCodeId => {
    return {
      method: "post",
      url: `${controllerName}/DeletePCode?pCodeId=${pCodeId}`,
    };
  },
  addSectorConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddSector`,
      data: payload,
    };
  },
  updateSectorConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateSector`,
      data: payload,
    };
  },
  deleteSectorConfig: sectorId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteSector?sectorId=${sectorId}`,
    };
  },
  addCriteriaTypeConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddCriteriaType`,
      data: payload,
    };
  },
  updateCriteriaTypeConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateCriteriaType`,
      data: payload,
    };
  },
  addCriteriaDetailConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddCriteriaDetail`,
      data: payload,
    };
  },
  updateCriteriaDetailsConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateCriteriaDetails`,
      data: payload,
    };
  },
  deleteCriteriaDetailsConfig: id => {
    return {
      method: "post",
      url: `${controllerName}/DeleteCriteriaDetails?id=${id}`,
    };
  },
  getLocationConfig: id => {
    return {
      method: "post",
      url: `${controllerName}/GetLocation?id=${id}`,
    };
  },
  addLocationConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddLocation`,
      data: payload,
    };
  },
  updateLocationConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateLocation`,
      data: payload,
    };
  },
  deleteLocationConfig: locationId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteLocation?locationId=${locationId}`,
    };
  },
  updateContactGroupsConfig: (payload, contactId) => {
    return {
      method: "post",
      url: `${controllerName}/UpdateContactGroups?contactId=${contactId}`,
      data: payload,
    };
  },
  addContactsConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddContacts`,
      data: payload,
    };
  },
  updateContactConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateContact`,
      data: payload,
    };
  },
  updateGroupConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/UpdateGroup`,
      data: payload,
    };
  },
  addGroupConfig: payload => {
    return {
      method: "post",
      url: `${controllerName}/AddGroup`,
      data: payload,
    };
  },
  updateGroupContactsConfig: (groupId, payload) => {
    return {
      method: "post",
      url: `${controllerName}/UpdateGroupContacts?groupId=${groupId}`,
      data: payload,
    };
  },
  deleteCriteriaTypeConfig: id => {
    return {
      method: "delete",
      url: `${controllerName}/DeleteCriteriaType?id=${id}`,
    };
  },
  deleteContactConfig: contactId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteContact?contactId=${contactId}`,
    };
  },
  deleteGroupConfig: groupId => {
    return {
      method: "post",
      url: `${controllerName}/DeleteGroup?groupId=${groupId}`,
    };
  },
  getGroupsContactsConfig: groupIds => {
    return {
      method: "post",
      url: `${controllerName}/GetGroupsContacts`,
      data: groupIds,
    };
  },
  updateGlobalSettingsConfig: data => {
    return {
      method: "post",
      url: `${controllerName}/UpdateGlobalSettings`,
      data: data,
    };
  },
  addTranslationConfig: data => {
    return {
      method: "post",
      url: `${controllerName}/AddTranslation`,
      data: data,
    };
  },
  updateTranslationConfig: (originalKey, originalUsedIn, data) => {
    return {
      method: "post",
      url: `${controllerName}/UpdateTranslation?originalKey=${originalKey}&originalUsedIn=${originalUsedIn}`,
      data: data,
    };
  },
  getLanguagesConfig: (skip = null, limit = null) => {
    let url = `${controllerName}/GetLanguages?skip=${skip}&limit=${limit}`;
    if (skip === null && limit === null) {
      url = `${controllerName}/GetLanguages`;
    }
    return {
      method: "get",
      url: url,
    };
  },
  getTranslationTableConfig: (skip, limit, key, value, section, usedIn) => {
    let url = `${controllerName}/GetTranslationTable?skip=${skip}&limit=${limit}${
      key ? `&key=${encodeURIComponent(key)}` : ""
    }${value ? `&value=${encodeURIComponent(value)}` : ""}${
      section ? `&section=${encodeURIComponent(section)}` : ""
    }${usedIn === 0 || usedIn ? `&usedIn=${usedIn}` : ""}`;
    return {
      method: "get",
      url: url,
    };
  },
  getActiveLanguagesConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetActiveLanguages`,
    };
  },
  exportTranslationConfig: (requestConfig, langCode) => {
    return {
      method: "get",
      url: `${controllerName}/ExportTranslation?langCode=${langCode}`,
      requestConfig: requestConfig,
    };
  },
  getCurrenciesConfig: (skip = null, limit = null) => {
    let url = `${controllerName}/GetCurrencies?skip=${skip}&limit=${limit}`;
    if (skip === null && limit === null) {
      url = `${controllerName}/GetCurrencies`;
    }
    return {
      method: "get",
      url: url,
    };
  },
  getPCodesConfig: (skip, limit) => {
    return {
      method: "get",
      url: `${controllerName}/GetPCodes?skip=${skip}&limit=${limit}`,
    };
  },
  getSectorsConfig: (skip = null, limit = null) => {
    let url = `${controllerName}/GetSectors?skip=${skip}&limit=${limit}`;
    if (skip === null && limit === null) {
      url = `${controllerName}/GetSectors`;
    }
    return {
      method: "get",
      url: url,
    };
  },
  getCriteriaTypesConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetCriteriaTypes`,
    };
  },
  getCriteriaTypeDetailsConfig: criteriaTypeId => {
    return {
      method: "get",
      url: `${controllerName}/GetCriteriaTypeDetails?criteriaTypeId=${criteriaTypeId}`,
    };
  },
  getLocationsConfig: (
    locationType,
    parentLocationId,
    skip = null,
    limit = null
  ) => {
    return {
      method: "get",
      url: `${controllerName}/GetLocations?${
        skip !== null ? `skip=${skip}&` : ""
      }${limit !== null ? `limit=${limit}&` : ""}locationType=${locationType}${
        parentLocationId ? `&parentLocationId=${parentLocationId}` : ""
      }`,
    };
  },
  getLocationsListConfig: (locationType, iso3) => {
    return {
      method: "get",
      url: `${controllerName}/GetLocationsList?locationType=${locationType}${iso3}`,
    };
  },
  getLocationsMapsConfig: (locationType, iso3) => {
    return {
      method: "get",
      url: `${controllerName}/GetLocationsMaps?locationType=${locationType}&iso3=${iso3}`,
    };
  },
  getContactsConfig: (skip, limit, keyword, unsubscribed) => {
    return {
      method: "get",
      url: `${controllerName}/GetContacts?${
        skip != null ? `skip=${skip}&` : ""
      }${limit != null ? `limit=${limit}` : ""}${
        keyword ? `&keyword=${encodeURIComponent(keyword)}` : ""
      }${unsubscribed != null ? `&unsubscribed=${unsubscribed}` : ""}`,
    };
  },
  getGroupsConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetGroups`,
    };
  },
  getContactGroupsConfig: contactId => {
    return {
      method: "get",
      url: `${controllerName}/GetContactGroups?contactId=${contactId}`,
    };
  },
  getGroupContactsConfig: groupId => {
    return {
      method: "get",
      url: `${controllerName}/GetGroupContacts?groupId=${groupId}`,
    };
  },
  getGlobalSettingsConfig: () => {
    return {
      method: "get",
      url: `${controllerName}/GetGlobalSettings`,
    };
  },
  getLogsConfig: () => {
    return {
      method: "post",
      url: `${controllerName}/GetLogs`,
    };
  },
  deleteTranslationConfig: (key, usedIn) => {
    return {
      method: "delete",
      url: `${controllerName}/DeleteTranslation?key=${key}&usedIn=${usedIn}`,
    };
  },
};
